<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transporte Interno</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep Voy Por Ti</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">
                    Solicitudes Transporte Interno
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- /.card-header -->
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-6">
                    <div class="card card-outline card-navy">
                      <div class="card-header">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-users"></i>
                            Datos del Usuario
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 form-group">
                            <label for="nombre">Nombre</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.nombre" readonly />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="identificacion">Identificación</label>
                            <input type="number" class="form-control form-control-sm" v-model="form.identificacion"
                              readonly />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="celular">Celular</label>
                            <input type="number" class="form-control form-control-sm" v-model="form.celular" readonly />
                          </div>
                          <div class="col-md-6 form-group">
                            <label for="area">Area</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.area" readonly />
                          </div>
                          <div class="col-md-6 form-group">
                            <label for="gerente_area">Nombre Gerente Area</label>
                            <v-select :class="[
                              $v.form.gerente_area.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ]" v-model="gerente_area" placeholder="Gerente Area" label="nombres"
                              :options="listasForms.gerente" class="form-control form-control-sm p-0"
                              @input="funcionarioGerente()"></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-bus"></i>
                            Datos de Transporte
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body table-responsive">
                        <div class="row">
                          <div class="col-md-6 form-group">
                            <label for="tipo_operacion">Tipo Operacion</label>
                            <select id="tipo_operacion" class="form-control form-control-sm" v-model="form.tipo_operacion"
                              :class="$v.form.tipo_operacion.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                                " :disabled="form1.estado != 1 && data_CaPa">
                              <option value>Seleccione...</option>
                              <option v-for="tipo in listasForms.tipo_operacion" :key="tipo.numeracion"
                                :value="tipo.numeracion">
                                {{ tipo.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6 form-group">
                            <label>Sitio</label>
                            <v-select :class="[
                              $v.form.sitio.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ]" v-model="form.sitio" placeholder="Geocercas" label="nombre"
                              :options="listasForms.sitios" class="form-control form-control-sm p-0"
                              :disabled="form1.estado != 1 && data_CaPa"></v-select>
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="tipo_vehiculo">Tipo Vehículo</label>
                            <select class="form-control form-control-sm" v-model="form.tipo_vehiculo" :class="$v.form.tipo_vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                              " :disabled="form1.estado != 1 && data_CaPa">
                              <option value="">Seleccione...</option>
                              <option v-for="tipo_vehiculo in listasForms.tipo_vehiculos" :key="tipo_vehiculo.id"
                                :value="tipo_vehiculo.id">
                                {{ tipo_vehiculo.nombre }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_ini">Fecha y Hora Inicial</label>
                            <div class="row ml-1">
                              <input type="date" v-model="form.fecha_ini" style="font-size: 13px"
                                class="form-control form-control-sm col-md-6 mr-3" :class="$v.form.fecha_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  " :disabled="form1.estado != 1 && data_CaPa" />
                              <input v-model="form.hora_ini" type="text" class="form-control form-control-sm col-md-5"
                                style="font-size: 13px" v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }" :class="$v.form.hora_ini.$invalid
    ? 'is-invalid'
    : 'is-valid'
  " :disabled="form1.estado != 1 && data_CaPa" />
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_fin">Fecha y Hora final</label>
                            <div class="row ml-1">
                              <input type="date" v-model="form.fecha_fin" style="font-size: 13px"
                                class="form-control form-control-sm col-md-6 mr-3" :class="$v.form.fecha_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  " :disabled="form1.estado != 1 && data_CaPa" />
                              <input v-model="form.hora_fin" type="text" class="form-control form-control-sm col-md-5"
                                style="font-size: 13px" v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }" :class="$v.form.hora_fin.$invalid
    ? 'is-invalid'
    : 'is-valid'
  " :disabled="form1.estado != 1 && data_CaPa" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-bus"></i>
                            Datos de Ruta
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body table-responsive">
                        <div class="row">
                          <div class="col-md-4 form-group">
                            <label for="origen">Origen</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.direccionOrigen" :class="$v.form.direccionOrigen.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                              " :disabled="form1.estado != 1 && data_CaPa" />
                          </div>
                          <div class="col-md-4 form-group">
                            <label for="destino">Destino</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.direccionDestino"
                              :class="$v.form.direccionDestino.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                                " :disabled="form1.estado != 1 && data_CaPa" />
                          </div>
                          <div class="col-md-3 form-group">
                            <label for="ruta">Ruta</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.ruta" :class="$v.form.ruta.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                              " :disabled="form1.estado != 1 && data_CaPa" />
                          </div>
                          <div class="form-group col-md-1 text-center">
                            <label>Ida y Vuelta</label>
                            <input type="checkbox" class="form-control form-control-sm p-0" v-model="form.ida_y_vuelta"
                              :disabled="form1.estado != 1 && data_CaPa" />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="motivo">Motivo</label>
                            <textarea class="form-control" v-model="form.motivo"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn bg-primary col-md-1" @click="save()" v-if="($store.getters.can(
                  'funcionariofrontera.solicitudInterna.create'
                ) ||
                    $store.getters.can(
                      'funcionariofrontera.solicitudInterna.edit'
                    )) &&
                  !$v.form.$invalid
                  " :disabled="form1.estado != 1 && data_CaPa">
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SolicitudInternaForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      data_CaPa: this.$route.params.accion == "Editar",
      form: {
        nombre: "",
        identificacion: null,
        celular: null,
        area: "",
        sitio: null,
        tipo_operacion: null,
        tipo_vehiculo: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        direccionOrigen: null,
        direccionDestino: null,
        ruta: null,
        ida_y_vuelta: false,
        motivo: null,
        metodo: null,
        gerente_area: null,
      },
      form1: {},
      gerente_area: null,
      cargando: false,
      user: [],
      listasForms: {
        tipo_operacion: [],
        tipo_vehiculos: [],
        sitios: [],
      },
      metodo: "",
    };
  },
  validations: {
    form: {
      tipo_operacion: {
        required,
      },
      sitio: {
        required,
      },
      tipo_vehiculo: {
        required,
      },
      fecha_ini: {
        required,
        validFormat: (value) =>
          /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ),
      },
      hora_ini: {
        required,
      },
      fecha_fin: {
        required,
        validFormat: (value) =>
          /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ),
      },
      hora_fin: {
        required,
      },
      direccionOrigen: {
        required,
      },
      direccionDestino: {
        required,
      },
      ruta: {
        required,
      },
      gerente_area: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form1 = this.$route.params.data_edit;

        this.form.user_id = this.form1.users[0].id;
        this.form.nombre = this.form1.users[0].name;
        this.form.identificacion = this.form1.users[0].n_document;
        this.form.celular = this.form1.users[0].cel;
        this.form.area = this.form1.users[0].funcionario[0].nArea;

        this.gerente_area = {
          id: this.form1.funcionario_gerente.id,
          nombres:
            this.form1.funcionario_gerente.nombres +
            " " +
            this.form1.funcionario_gerente.apellidos,
        };

        this.form.gerente_area = this.form1.gerente_area;

        this.form.id = this.form1.id;
        this.form.tipo_operacion = this.form1.tipo_operacion;
        this.form.sitio = this.form1.sitio;
        this.form.tipo_vehiculo = this.form1.tipo_vehiculo_id;
        this.form.fecha_ini = this.form1.fecha_hora_inicio_servicio.slice(
          0,
          10
        );
        this.form.hora_ini = this.form1.fecha_hora_inicio_servicio.slice(
          11,
          19
        );
        this.form.fecha_fin = this.form1.fecha_hora_fin_servicio.slice(0, 10);
        this.form.hora_fin = this.form1.fecha_hora_fin_servicio.slice(11, 19);

        this.form.tipo_vehiculo = this.form1.tipo_vehiculo_id;

        this.form.direccionOrigen = this.form1.direccion_origen;
        this.form.direccionDestino = this.form1.direccion_destino;
        this.form.ruta = this.form1.ruta;
        this.form.ida_y_vuelta = this.form1.ida_y_vuelta;
        this.form.motivo = this.form1.motivo;

        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.getUser();
        this.cargando = false;
      }
    },

    back() {
      return this.$router.replace(
        "/TepVoyPorTi/FuncionariosSolicitudesInternas"
      );
    },

    getTipoOperacion() {
      axios
        .get("/api/lista/80", { params: { in_number: [1, 3] } })
        .then((response) => {
          this.listasForms.tipo_operacion = response.data;
        });
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = await this.user.funcionario[0].nArea;
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    funcionarioGerente() {
      this.form.gerente_area = null;
      if (this.gerente_area) {
        this.form.gerente_area = this.gerente_area.id;
      }
    },

    async getGerente() {
      await axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            /*             gerente_area: 1, */
          },
        })
        .then((response) => {
          this.listasForms.gerente = response.data;
        });
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
            in_id: [5],
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculos = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.metodo = this.metodo;
        await axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/SolicitudesInternas",
          data: this.form,
        })
          .then(() => {
            this.$route.params.accion = "Editar";
            this.$swal({
              icon: "success",
              title: "Se actualizó la solicitud correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
            this.back();
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  async mounted() {
    await this.getTipoOperacion();
    await this.getGerente();
    await this.getTipoVehiculo();
    await this.buscarSitios();
    await this.init();
  },
};
</script>
